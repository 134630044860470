import * as React from "react";

const DownloadIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
		<path
			fill="#fff"
			d="M14.918 7.549h-1.704V2.647c0-.54-.482-.98-1.071-.98H7.857c-.59 0-1.071.44-1.071.98V7.55H5.082c-.953 0-1.436 1.059-.76 1.677l4.917 4.5a1.138 1.138 0 0 0 1.511 0l4.918-4.5c.675-.618.203-1.677-.75-1.677ZM2.5 17.353c0 .54.482.98 1.071.98H16.43c.589 0 1.071-.44 1.071-.98s-.482-.98-1.071-.98H3.57c-.589 0-1.071.44-1.071.98Z"
		/>
	</svg>
);
export default DownloadIcon;
