import clsx from "clsx";
import { useLayoutEffect, useState } from "react";
import { keyframes, styled } from "theme";

const Marquee = ({ children, className, speed = 15, stopOnHover = false }) => {
	const [animating, setAnimating] = useState(true);

	const handleMouseOver = () => {
		if (window.innerWidth < 768) return;
		if (!stopOnHover) return;
		setAnimating(false);
	};

	const handleMouseOut = () => {
		if (window.innerWidth < 768) return;
		if (!stopOnHover) return;
		setAnimating(true);
	};

	return (
		<div
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
			className={clsx("flex overflow-hidden", className)}
		>
			<Marq animating={animating} speed={speed}>
				{children}
			</Marq>
			<Marq animating={animating} delay={speed / 2} speed={speed}>
				{children}
			</Marq>
		</div>
	);
};

export default Marquee;

const animationFrames = keyframes`
0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
  `;

const Marq = styled.div`
	display: flex;
	animation-name: ${animationFrames};
	animation-duration: ${(props) => props.speed}s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;

	${({ animating }) =>
		animating ? "animation-play-state: running;" : "animation-play-state: paused;"}
`;
