import { StarRounded } from "@material-ui/icons";
import DownloadIcon from "assets/svgs/DownloadIcon";
import { createBranchDeepLink } from "libs/BranchIO";
import { BRANCH_APP_DOWNLOAD_CAMPAIGNS } from "utils/constants";
import getTrackingParams from "utils/getTrackingParams";
import { getUrlPath } from "utils/helpers";
import Image from "next/image";
import useIsDesktop from "hooks/useIsDesktop";
import { useEffect, useState } from "react";

const DownloadApna: React.FC = () => {
	const handlePlayStoreIconClick = async () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Download app click", {
				page: getUrlPath(),
				Source: "Home Page",
				Section: "Download apna app",
				pageType: "download apna app",
				actionType: "play store icon",
			});
		});

		const playStoreUrl = await createBranchDeepLink({
			id: "",
			campaign: BRANCH_APP_DOWNLOAD_CAMPAIGNS.playStoreIcon,
			pageType: "Home page",
			utm_source: "Apna Main Website",
			utm_campaign: "Download apna app page",
			utm_medium: "button",
			...getTrackingParams(null, true),
		});

		window.open(playStoreUrl, "_blank");
	};

	return (
		<div className="mx-auto max-w-screen-xl">
			<div className="mx-4 my-[72px] flex flex-col rounded-[16px] border bg-gradient-to-r from-[#f9f4ff] to-[#f9f4ff] md:mx-4 md:my-[100px] md:flex-row md:rounded-[24px]">
				<div className="my-4 shrink-[4] grow px-4 md:my-[52px] md:pl-[48px]">
					<h4 className="m-0 text-[32px] text-[#512194] md:text-[48px]">
						Download Apna app!
					</h4>
					<p className="mt-4 hidden text-md font-semibold md:block">
						Unlimited job applications | HRs contact you directly | Track your
						Applications
					</p>
					<ul className="mt-5 list-disc text-sm font-semibold md:hidden">
						<li>Unlimited job applications</li>
						<li>HRs contact you directly</li>
						<li>Track your Applications</li>
					</ul>
					<div className="mt-[48px] flex hidden max-w-[320px] items-center rounded-lg bg-white p-4 md:flex">
						<h5 className="m-0 mr-2 text-md font-semibold">
							Scan QR to download Apna app
						</h5>
						<div className="shrink-0 rounded-lg border p-2">
							<Image
								loading="lazy"
								layout="fixed"
								alt="QR code. Scan and download apna app"
								width={120}
								height={120}
								src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/app-qr.png"
							/>
							{/* <input
							value={number}
							maxLength={10}
							type="tel"
							onChange={(e) => {
								const { value } = e.target;
								const formattedValue = value.replace(/\D/g, ""); // Remove non-digit characters

								if (formattedValue.length <= 10) {
									setNumber(formattedValue);
								}
							}}
							placeholder="Enter mobile number"
							className="grow bg-transparent p-3 outline-none"
						/>
						<Button
							disabled={number.length < 10}
							className="mr-[2px]"
							variant="primary"
							size="medium"
						>
							Get link
						</Button> */}
						</div>
					</div>
				</div>

				<div className="relative flex flex-row-reverse md:flex-row md:overflow-hidden">
					<img
						loading="lazy"
						alt="apna mobile"
						src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/apna-app.png"
						className="md:left[-30px] relative hidden !w-[55%] object-contain md:top-[47px] md:block"
					/>
					<img
						loading="lazy"
						alt="apna mobile"
						src="https://storage.googleapis.com/mumbai_apnatime_prod/apna-home/downloadMobile.png"
						className="absolute top-[-65px] block !w-[205px] object-contain md:hidden"
					/>

					<div className="mb-[30px] flex grow flex-col items-start justify-end md:grow-0 md:items-end">
						<div className="bg-gradient-to-l from-[#f9f4ff] to-[#f3ebff] px-[20px] py-5 text-center md:bg-gradient-to-r md:px-[52px]">
							<div className="flex items-center text-[24px] font-bold text-[#512194] md:text-[32px]">
								<StarRounded
									fontSize="large"
									className="text-[#FFD166]"
								/>
								&nbsp;4.4
							</div>
							<p className="m-0 text-sm font-semibold text-[#512194] md:text-md">
								5L reviews
							</p>
						</div>
						<div className="mt-3 bg-gradient-to-l from-[#f9f4ff] to-[#f3ebff] px-[20px] py-5 text-center md:mt-[34px] md:bg-gradient-to-r md:px-[52px]">
							<div className="flex items-center text-[24px] font-bold text-[#512194] md:text-[32px]">
								<DownloadIcon
									width={32}
									height={32}
									className="rounded-full bg-[#F0E4FF] pl-[6px] pt-[6px] text-[#FFD166] [&>path]:fill-[#512194]"
								/>
								&nbsp;1&nbsp;cr+
							</div>
							<p className="m-0 text-sm font-semibold text-[#512194] md:text-md">
								App&nbsp;downloads
							</p>
						</div>
					</div>
				</div>

				<div className="m-4 mt-[48px] flex items-center rounded-lg bg-white p-4 md:hidden">
					<h5 className="m-0 mr-1 text-md font-semibold">
						Download it from Play Store
					</h5>

					<Image
						loading="lazy"
						onClick={handlePlayStoreIconClick}
						className="-m-2 inline-block"
						width={160}
						height={64}
						layout="fixed"
						src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
						alt="Get it on google play"
					/>
				</div>
			</div>
		</div>
	);
};

export default DownloadApna;
